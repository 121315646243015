import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon } from "@heroicons/react/24/outline";
import logo from "../images/logo.png";
import bgHero from "../images/heroBg.png";
import Spline from "@splinetool/react-spline";

const navigation = [
  { name: "Gaza", href: "#" },
  { name: "Contact Us", href: "https://forms.gle/skENDrFyvrJgHKfW7" },
];

export default function HeroSection() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleClick = (event) => {
    event.preventDefault();
    const pages = [
      "https://www.newyorker.com/news/the-weekend-essay/the-view-from-my-window-in-gaza",
      "https://www.bbc.com/news/newsbeat-44124396",
    ];
    const randomPage = pages[Math.floor(Math.random() * pages.length)];
    window.location.href = randomPage;
  };

  return (
    <div className="bg-custom-bg">
      <header className="absolute inset-x-5 top-5 z-50 ">
        <nav
          className="flex items-center justify-between p- lg:px-8"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img className="h-8 w-auto" src={logo} alt="Company logo" />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-sm font-light leading-6 text-primary"
              >
                {item.name}
              </a>
            ))}
          </div>
        </nav>
        <Dialog
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-50" />
        </Dialog>
      </header>

      <div className="relative isolate overflow-hidden pt-14">
        <img
          src={bgHero}
          alt="Hero background"
          className="absolute inset-0 -z-10 h-full w-full object-cover"
        />
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#2F814C] to-[#106029] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div
          className="absolute inset-x-0 bottom-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-bottom--20"
          aria-hidden="true"
        >
          <div
            className="relative right-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1 rotate-[0deg] bg-gradient-to-tr from-[#AC242B] to-[#80151A] opacity-30 sm:right-[calc(10%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>

        <div className="mx-auto max-w-5xl px-4 py-32 sm:px-6 lg:px-8 lg:py-32">
          <div className="hidden sm:mb-2 sm:flex sm:justify-center">
            <div className="relative rounded-full px-6 py-4 text-sm leading-6 text-gray-400 ring-1 ring-white/10 hover:ring-white/20">
              <span className="mr-3">Read an article about Gaza</span>{" "}
              <button
                className="font-semibold text-primary"
                onClick={handleClick}
              >
                Go to page <span aria-hidden="true">&rarr;</span>
              </button>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between">
            <div className="text-left lg:w-2/3 lg:pr-8">
              <h1 className="text-4xl font-extrabold tracking-tight text-transparent bg-primary-gradient bg-clip-text sm:text-9xl">
                FundMap
              </h1>
              <h3 className="text-4xl font-extrabold tracking-tight text-secondary sm:text-5xl">
                Mapping Change
              </h3>
              <p className="mt-6 text-lg leading-8 text-primary">
                Visualise how your aid brings hope and relief to communities
                across five governorates, from <b>North Gaza to Rafah.</b>
              </p>
              <div className="mt-10 flex items-center gap-x-6">
                <a
                  href="https://www.gofundme.com/s?q=Gaza+strip"
                  className="rounded-md bg-button px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-buttonhover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
                >
                  Donate Now
                </a>
                <a
                  href="https://www.youtube.com/watch?v=hdYZtRQ_eeo&t=256s&pp=ygUUaXNyYWVsIGFuZCBwYWxlc3RpbmU%3D"
                  className="text-sm font-semibold leading-6 text-white"
                >
                  Learn more <span aria-hidden="true">→</span>
                </a>
              </div>
            </div>
            <div className="mt-10 lg:mt-0 lg:w-1/3 lg:pl-8">
              <div className="relative h-64 w-full sm:h-80 lg:h-96">
                <Spline scene="https://prod.spline.design/88nJR3vKHIqO7s4w/scene.splinecode" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
