import React from "react";
import { Helmet } from "react-helmet";
import "./App.css";
import LandingPage from "./pages/LandingPage";

function App() {
  return (
    <>
      <Helmet>
        <title>FundMap</title>
      </Helmet>
      <div>
        <LandingPage />
      </div>
    </>
  );
}

export default App;
