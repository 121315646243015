import findadonorBg from "../images/findadonerBg.png";

export default function FindADonorSection() {
  return (
    <div className="relative isolate overflow-hidden bg-custom-bg px-6 py-24 sm:py-32 lg:px-8">
      <img
        src={findadonorBg}
        alt=""
        className="absolute inset-0 -z-10 h-full w-full object-cover opacity-40"
      />
      <div
        className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#AC242B] to-[#80151A] opacity-40"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      <div
        className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
        aria-hidden="true"
      >
        <div
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#AC242B] to-[#80151A] opacity-40"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      <div className="mx-auto max-w-2xl text-center">
        <h1 className="text-5xl font-extrabold tracking-tight text-primary sm:text-5x">
          Find a Fundraiser
        </h1>
        <p className="mt-6 text-lg leading-8 text-primary">
          Find a campaign tailor-made to exactly whom you want your money to go
          to. Choose your preference in categories like{" "}
          <b>Closest to target, Type of need, Number of donations,</b> and more!
          <br></br>
          <br></br>
          <button className="rounded-md bg-button px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-buttonhover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary mt-8">
            Coming Soon
          </button>
        </p>
      </div>
    </div>
  );
}
