import React from "react";
import HeroSection from "../components/HeroSection";
import MapSection from "../components/MapSection";
import ContentSection from "../components/ContentSection";
import Divider from "../components/Divider";
import FindADonorSection from "../components/FindADonorSection";
import Footer from "../components/Footer";

const LandingPage = () => {
  return (
    <div>
      <HeroSection />
      <MapSection />
      <Divider />
      <ContentSection />
      <FindADonorSection />
      <Footer />
    </div>
  );
};

export default LandingPage;
