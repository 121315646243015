export default function ContentSection() {
  return (
    <div className="bg-custom-bg py-32">
      {" "}
      {/* Changed py-24 sm:py-32 to py-32 */}
      <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
        {" "}
        <div className="mx-auto max-w-5xl lg:mx-0">
          <p className="text-base font-semibold leading-7 text-button">
            Extra Information
          </p>
          <h1 className="text-4xl font-extrabold tracking-tight text-transparent bg-primary-gradient bg-clip-text sm:text-5xl">
            Disclaimers
          </h1>
          <div className="mt-10 grid max-w-xl grid-cols-1 gap-8 text-base leading-7 text-primary lg:max-w-none lg:grid-cols-2">
            <div>
              <p>
                All information is retrieved from{" "}
                <a
                  href="https://www.gofundme.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-red-800 hover:text-red-600 underline"
                >
                  {" "}
                  <b>GoFundMe</b>
                </a>
                . The data is collected based on the fund's location, precise to
                the district but not absolute.
              </p>
              <p className="mt-8">
                Cases with insufficient information or difficult categorization
                have been omitted. The data is relative and circumstantial, thus
                may not be 100% accurate.
              </p>
            </div>
            <div>
              <p>
                Information regarding the <b>Gaza City</b> district may be less
                accurate due to discrepancies between its name and 'Gaza Strip.'
              </p>
              <p className="mt-8">
                The data will be updated once every 2 weeks to keep up with
                changes in current and new campaigns hosted on GoFundMe.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
