export default function Divider() {
  return (
    <div className="relative bg-custom-bg">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-3/5 mx-auto border-t border-secondary" />
      </div>
      <div className="relative flex justify-center">
        <span className="bg-custom-bg px-2 text-sm text-primary">Continue</span>
      </div>
    </div>
  );
}
