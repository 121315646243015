import React, { useState, useEffect, useCallback } from "react";
import { MapContainer, TileLayer, CircleMarker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import * as d3 from "d3";

const regionsData = {
  "North Gaza": {
    lat: 31.556,
    lng: 34.495,
    bounds: [
      [31.5015, 34.457],
      [31.583, 34.54],
    ],
  },
  "Gaza City": {
    lat: 31.502,
    lng: 34.466,
    bounds: [
      [31.475, 34.425],
      [31.536, 34.51],
    ],
  },
  "Deir al-Balah": {
    lat: 31.42,
    lng: 34.35,
    bounds: [
      [31.37, 34.3],
      [31.475, 34.425],
    ],
  },
  "Khan Yunis": {
    lat: 31.344,
    lng: 34.303,
    bounds: [
      [31.28, 34.25],
      [31.37, 34.38],
    ],
  },
  Rafah: {
    lat: 31.284,
    lng: 34.25,
    bounds: [
      [31.217, 34.2],
      [31.28, 34.29],
    ],
  },
};

// Function to get a random position within the bounds of a region
const getRandomPositionWithinBounds = (bounds) => {
  const lat = bounds[0][0] + Math.random() * (bounds[1][0] - bounds[0][0]);
  const lng = bounds[0][1] + Math.random() * (bounds[1][1] - bounds[0][1]);
  return [lat, lng];
};

function parseAmountAndCurrency(progressString) {
  console.log("Parsing progress string:", progressString);
  if (!progressString || typeof progressString !== "string") {
    console.warn("Invalid progress string:", progressString);
    return [0, "", 0, ""];
  }

  const match = progressString.match(
    /(([£€$])?([\d,]+)([£€$])?)\s*(?:USD|CAD)?\s*raised\s+of\s+(([£€$])?([\d,]+)([£€$])?)\s*(?:USD|CAD)?\s*target/i
  );

  if (match) {
    const [
      ,
      ,
      // skip raisedFull
      raisedPrefix,
      raisedAmountStr,
      raisedSuffix, // skip targetFull
      ,
      targetPrefix,
      targetAmountStr,
      targetSuffix,
    ] = match;

    const raisedAmount = parseFloat(raisedAmountStr.replace(/,/g, ""));
    const targetAmount = parseFloat(targetAmountStr.replace(/,/g, ""));

    const raisedCurrency = raisedPrefix || raisedSuffix || "";
    const targetCurrency = targetPrefix || targetSuffix || "";

    console.log("Parsed amounts and currencies:", {
      raisedAmount,
      raisedCurrency,
      targetAmount,
      targetCurrency,
    });

    return [raisedAmount, raisedCurrency, targetAmount, targetCurrency];
  }

  console.warn("Unable to parse progress string:", progressString);
  return [0, "", 0, ""];
}

export default function MapSection() {
  const [activeButton, setActiveButton] = useState("Closest to target");
  const [campaigns, setCampaigns] = useState([]);
  const [visualizationMode, setVisualizationMode] = useState("progress");

  const maxTarget =
    campaigns.length > 0 ? Math.max(...campaigns.map((c) => c.target)) : 0;
  console.log("Current maxTarget:", maxTarget);

  // Memoize the getColor function
  const getColor = useCallback(
    (value, type) => {
      if (type === "progress") {
        if (value === undefined || value === null) {
          return "#cccccc"; // Default color for invalid data
        }
        if (value <= 20) return "#80151A";
        if (value <= 40) return "#CA444B";
        if (value <= 60) return "#EE9196";
        if (value <= 80) return "#90E19E";
        if (value <= 90) return "#47B259";
        return "#10671F";
      } else if (type === "target") {
        const normalizedValue = (value / maxTarget) * 100;
        if (value === undefined || value === null) {
          return "#cccccc"; // Default color for invalid data
        }
        if (normalizedValue <= 10) return "#80151A";
        if (normalizedValue <= 30) return "#CA444B";
        if (normalizedValue <= 60) return "#EE9196";
        if (normalizedValue <= 100) return "#90E19E";
        return "#10671F";
      }
    },
    [maxTarget]
  );

  const parseCampaignRow = useCallback((row) => {
    console.log("Parsing row:", row);
    if (!row || typeof row !== "object") {
      console.warn("Invalid row data:", row);
      return null;
    }

    if (!row.title || !row.progress || !row.url_index) {
      console.warn("Missing required fields in row:", row);
      return null;
    }

    const [raisedAmount, raisedCurrency, targetAmount] = parseAmountAndCurrency(
      row.progress
    );

    console.log("Parsed campaign:", {
      title: row.title,
      raised: raisedAmount,
      target: targetAmount,
      progress: targetAmount ? (raisedAmount / targetAmount) * 100 : 0,
      currency: raisedCurrency,
      url_index: `https://www.gofundme.com/${row.url_index.split("?")[0]}`,
    });

    return {
      title: row.title,
      raised: raisedAmount,
      target: targetAmount,
      progress: targetAmount ? (raisedAmount / targetAmount) * 100 : 0,
      currency: raisedCurrency,
      url_index: `https://www.gofundme.com/${row.url_index.split("?")[0]}`,
    };
  }, []); // Empty dependency array as it doesn't depend on any props or state

  useEffect(() => {
    const loadCampaignData = async () => {
      console.log("Starting to load campaign data...");

      const campaignData = [];
      const regionFiles = {
        "Gaza City": "/data/GazaCity_campaign_data.csv",
        "Khan Yunis": "/data/KhanYounis_campaign_data.csv",
        "Deir al-Balah": "/data/DeirElBalah_campaign_data.csv",
        "North Gaza": "/data/NorthGaza_campaign_data.csv",
        Rafah: "/data/Rafah_campaign_data.csv",
      };

      for (const region in regionFiles) {
        try {
          console.log(`Loading data for region: ${region}`);
          const response = await fetch(regionFiles[region]);

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const contentType = response.headers.get("content-type");
          if (!contentType || !contentType.includes("text/csv")) {
            console.error(`Unexpected content type: ${contentType}`);
            const text = await response.text();
            console.error(`Response body: ${text.substring(0, 200)}...`);
            throw new Error("Received non-CSV content");
          }

          const csvText = await response.text();
          console.log(
            `Raw CSV data for ${region} (first 200 characters):`,
            csvText.substring(0, 200)
          );
          const data = await d3.csv(regionFiles[region]);

          // Log the first row of data (if it exists) to check its structure
          if (data.length > 0) {
            console.log(`Sample row for ${region}:`, JSON.stringify(data[0]));
          } else {
            console.log(`No data found for ${region}`);
          }

          // Log the number of rows
          console.log(`Number of rows for ${region}: ${data.length}`);

          const regionCampaigns = data
            .map((row) => {
              const regionData = regionsData[region];
              if (!regionData) {
                console.warn(`No region data found for ${region}`);
                return null;
              }

              const campaign = parseCampaignRow(row);
              if (!campaign || campaign.target === 0) return null; // Filter out campaigns with zero target

              if (!campaign) return null;

              const position = getRandomPositionWithinBounds(regionData.bounds);
              return {
                ...campaign,
                region,
                position,
              };
            })
            .filter(Boolean); // Remove null entries

          console.log(`Processed data for region: ${region}`, regionCampaigns);
          campaignData.push(...regionCampaigns);
        } catch (error) {
          console.error(`Error loading data for region: ${region}`, error);
        }
      }

      console.log("All campaign data loaded:", campaignData);
      setCampaigns(campaignData);
    };

    loadCampaignData();
  }, [parseCampaignRow]);

  const handleClick = (buttonType, event) => {
    event.preventDefault();
    setActiveButton(buttonType);
    setVisualizationMode(
      buttonType === "Closest to target" ? "progress" : "target"
    );
  };

  return (
    <div className="bg-custom-bg py-32">
      <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-full lg:mx-0">
          <h1 className="text-4xl font-extrabold tracking-tight text-transparent bg-primary-gradient bg-clip-text sm:text-5xl md:text-6xl">
            Gaza Relief Campaign Map
          </h1>
          <p className="mt-6 text-lg leading-8 text-primary sm:text-xl">
            Visualize and support ongoing fundraising efforts across Gaza. Each
            dot represents a campaign, with color intensity indicating progress
            towards the funding goal.
          </p>
          <div className="mt-4 text-sm text-gray-600 sm:text-base">
            <p>ℹ️ Click on a dot to view campaign details and donation link</p>
            <p>
              🎨 Shades range from{" "}
              <span className="text-red-600 font-semibold">Red</span> to{" "}
              <span className="text-green-600 font-semibold">Green</span>{" "}
              depending on the status of the campaign
            </p>
          </div>
        </div>

        <div className="mt-10 flex items-center gap-x-6">
          <a
            href="/"
            onClick={(event) => handleClick("Closest to target", event)}
            className={`rounded-md px-3.5 py-2.5 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary ${
              activeButton === "Closest to target"
                ? "bg-button text-white"
                : "text-primary bg-transparent hover:bg-buttonhover"
            }`}
          >
            Closest to target
          </a>
          <a
            href="/"
            onClick={(event) => handleClick("Highest target amount", event)}
            className={`rounded-md px-3.5 py-2.5 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary ${
              activeButton === "Highest target amount"
                ? "bg-button text-white"
                : "text-primary bg-transparent hover:bg-buttonhover"
            }`}
          >
            Highest target amount
          </a>
        </div>
        <div className="mt-10 relative">
          <div className="relative mt-10 h-96 w-full">
            <MapContainer
              center={[31.4, 34.3]}
              zoom={10}
              className="h-96 w-full"
            >
              <TileLayer
                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                attribution='&copy; <a href="https://www.esri.com">Esri</a> &mdash; 
      Source: Esri, Maxar, Earthstar Geographics, and the GIS User Community'
              />

              {campaigns.map((campaign, index) => (
                <CircleMarker
                  key={`${index}-${visualizationMode}`} // Add visualizationMode to the key
                  center={campaign.position}
                  radius={1}
                  color={getColor(
                    visualizationMode === "progress"
                      ? campaign.progress
                      : campaign.target,
                    visualizationMode
                  )}
                  fillOpacity={1}
                  weight={1}
                >
                  <Popup>
                    <div>
                      <strong>{campaign.title}</strong>
                      <br />
                      Raised: {campaign.currency}
                      {campaign.raised.toLocaleString()}
                      <br />
                      Target: {campaign.currency}
                      {campaign.target.toLocaleString()}
                      <br />
                      Progress: {campaign.progress.toFixed(2)}%
                      <br />
                      <a
                        href={campaign.url_index}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Campaign
                      </a>
                    </div>
                  </Popup>
                </CircleMarker>
              ))}
            </MapContainer>
          </div>
        </div>
      </div>
    </div>
  );
}
